@use 'variables';
@use 'mixins';

shared-address-form {
  .address-input-switch {
    color: variables.$color-blue-active;
    font-size: 11px;
    cursor: pointer;
    text-align: end;
  }

  .manual-form-container {
    background-color: variables.$color-background-canvas;
    padding: 1rem;
    border-radius: variables.$border-radius-small;
    margin: 4px 0;
  }

  label {
    width: 100%;
    @include mixins.font-xxs();
  }
}
